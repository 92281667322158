import React from 'react';
import { graphql } from 'gatsby';
import { Link, navigate } from 'gatsby-plugin-intl';
import Layout from '../templates/Layout';

const IndexPage = ({ data }) => {
  typeof window !== undefined && navigate('/guide');
  return <Layout>{/* <Link to="/blog">Go to blog</Link> */}</Layout>;
};

export default IndexPage;
